import React, { useEffect, Fragment, useState, useMemo, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { values, cloneDeep } from 'lodash';
import { createMeshsArray, LoadingProgress, Renderer, Splitter, ConditionNavigation } from '@web-3d-tool/shared-ui';
import { shellActions, rendererActions } from '@web-3d-tool/redux-logic';
import { strings } from '@web-3d-tool/localization';
import { TOOGLE_MENU360 } from '@web-3d-tool/shared-logic/src/constants/tools.constants';
import * as configValues from '@web-3d-tool/shared-logic/src/constants/configurationValues.constants';
import { menuTypes360 } from '@web-3d-tool/shared-logic/src/constants/menuTypes360.constants';
import { AOHSService, storeCommunicationService } from '@web-3d-tool/shared-logic';
import { pendo } from '@itero/itero-pendo';

import {
  utils,
  logger,
  logToTimber,
  logToTimberBI,
  eventBus,
  globalEventsKeys,
  cacheManager,
  cacheKeys,
  settingsManager,
  requestsManager,
  featureAvaliability,
  biMethods,
  appSettingsService,
  wasmService,
  modelTypes,
} from '@web-3d-tool/shared-logic';
import classNames from 'classnames';
import { appSettingsManager } from '@web-3d-tool/shared-logic/src/app-settings-manager';
import ToolsContainer from './components/ToolsContainer';
import AppPreset from './components/Preset';
import styles from './App.module.css';

const { ORIGIN, COMPARE } = modelTypes;
const { imperativeThreeObjectsReady, cameraStoppedMoving, twoFingersDoubleTap } = rendererActions;
const { appLoaded, presetLoaded, updateReduxShellState } = shellActions;

const prepareMetadata = ({ currentMetadata, materialsObject, model }) => {
  const metadata = cloneDeep(currentMetadata);
  [...values(metadata.lower_jaw), ...values(metadata.upper_jaw)].forEach((object) => {
    if (object.material) {
      const { uuid } = object.material;
      object.material = materialsObject[uuid];
    }
  });
  const geometries = Object.values(model.objects).map((geometry) => ({ geometry }));
  const meshes = createMeshsArray(model, metadata);

  return { geometries, meshes };
};

const renderStage = ({
  model,
  compareModel,
  metadata: currentMetadata,
  compareMetadata,
  materialsObject,
  compareMaterialsObject,
  stage,
  appProps,
  onMount,
  onMountCompare,
  resetCameraRotationOnUpdate,
  menuType360,
  isOnLanding,
  imageFrameDimentions,
  isModelCompareEnabled,
  isModelCompareActive,
  isModelSynced,
  isSplittedViewWithSidePluginActive,
  isModelCompareInDifferentMode,
  setExclusivePluginsStateChange,
  compareRowIndex,
  isAOHS,
  isBothModelsHasNiriData,
}) => {
  const numberOfItems = stage.reduce((count, current) => count + current.length, 0);

  return (
    <Splitter
      propsMatrix={stage}
      key={numberOfItems} //we want to cause rerendering in case of spliting renderer
      isOnLanding={isOnLanding}
      isModelCompareActive={isModelCompareActive}
      imageFrameDimentions={imageFrameDimentions}
      isSplittedViewWithSidePluginActive={isSplittedViewWithSidePluginActive}
      translationsForSplitter={strings}
      compareRowIndex={compareRowIndex}
      renderComp={(props, rowIndex, rendererInRowIndex) => {
        const isModelCompareActivated = !!(isModelCompareEnabled && compareMetadata && compareModel);
        const modelToPrepare =
          isModelCompareActivated && rendererInRowIndex === compareRowIndex
            ? {
                model: compareModel,
                currentMetadata: compareMetadata,
                materials: compareMaterialsObject,
                type: COMPARE,
                isLuminaScan: utils.getIsScanOriginLumina(COMPARE),
              }
            : {
                model,
                currentMetadata,
                materials: materialsObject,
                type: ORIGIN,
                isLuminaScan: utils.getIsScanOriginLumina(ORIGIN),
              };

        const { geometries, meshes } = prepareMetadata({
          currentMetadata: modelToPrepare.currentMetadata,
          materialsObject: modelToPrepare.materials,
          model: modelToPrepare.model,
        });

        const rendererProps = {
          meshes: meshes,
          isModelCompareActive,
          isModelSynced,
          split: numberOfItems,
          rendererInRowIndex,
          setExclusivePluginsStateChange,
          isOnLanding,
          modelType: modelToPrepare.type,
          isLuminaScan: modelToPrepare.isLuminaScan,
          isModelCompareEnabled,
          isAOHS,
          isBothModelsHasNiriData,
          bite: modelToPrepare.currentMetadata.bite,
        };
        if (
          isModelCompareActivated ||
          (isSplittedViewWithSidePluginActive && rowIndex === 0) ||
          (rowIndex === 0 && rendererInRowIndex === 0)
        ) {
          // we are sending the three objects via redux only for the first renderer
          rendererProps.getThreeJSObjects = (objects) =>
            appProps.imperativeThreeObjectsReady({
              objects,
              split: rendererInRowIndex,
              modelType: modelToPrepare.type,
            });
          rendererProps.onTap2Fingers = (controls) =>
            eventBus.subscribeToEvent(globalEventsKeys.TAP_2_FINGERS, controls);
          rendererProps.onDoubletap2Fingers = (controls) => appProps.twoFingersDoubleTap(controls);
        }

        rendererProps.onCameraMove = ({ target: { camera } }) =>
          eventBus.raiseEvent(globalEventsKeys.CAMERA_CHANGED, { camera });
        rendererProps.onCameraStopMoving = ({ target: { camera } }) =>
          appProps.cameraStoppedMoving({
            camera,
            arrayIndex: rowIndex,
            inArrayIndex: rendererInRowIndex,
          });

        return (
          <>
            <Renderer
              {...props}
              {...rendererProps}
              geometries={geometries}
              onMount={isModelCompareActivated ? onMountCompare : onMount}
              id={`renderer-${rowIndex}-${rendererInRowIndex}`}
              resetCameraRotationOnUpdate={resetCameraRotationOnUpdate}
              menuType360={menuType360}
              rendererInRowIndex={rendererInRowIndex}
              numberOfItems={numberOfItems}
              isSplittedViewWithSidePluginActive={isSplittedViewWithSidePluginActive}
              imageFrameDimentions={imageFrameDimentions}
              isModelCompareInDifferentMode={isModelCompareInDifferentMode}
            />

            <>
              {isModelCompareActivated ? (
                <>
                  {rendererInRowIndex === compareRowIndex ? (
                    <ToolsContainer
                      key={rendererInRowIndex}
                      style={{ position: 'absolute' }}
                      eventPref={'Compare'}
                      dragObjectName={'draggableObjectCompare'}
                    />
                  ) : (
                    <ToolsContainer key={rendererInRowIndex} style={{ position: 'absolute' }} />
                  )}
                </>
              ) : (
                <>{rendererInRowIndex === 0 && <ToolsContainer style={{ position: 'absolute' }} />}</>
              )}
            </>
          </>
        );
      }}
    />
  );
};

const PluginsViews = ({ pluginsViews }) =>
  values(pluginsViews).map((view, index) => <Fragment key={index}>{view}</Fragment>);

const handleResizeWindow = (e) => {
  const { innerWidth: width, innerHeight: height } = e.target;
  eventBus.raiseEvent(globalEventsKeys.RESIZING, { width, height });
  storeCommunicationService.updateStore({ size: { width, height } });
};

const appVersion = utils.getAppVersion();

const logAboutAppLoaded = (timeToLoad) => {
  const dataToLog = {
    module: 'app',
    version: appVersion,
    modelUrl: requestsManager.getModelUrl(),
    niriUrl: requestsManager.getNiriFilePath(),
    bffUrl: settingsManager.getConfigValue(configValues.serverEndpoint),
    timeToLoad,
  };

  logger
    .info('App rendered')
    .data(dataToLog)
    .to(['analytics', 'host'])
    .end();

  //Should probably remove this in 21A, as not to add to console blindness:
  console.log('Time till rendering is shown on screen: ', timeToLoad);

  logToTimber({
    timberData: {
      action: 'loaded',
      module: 'web-viewer',
      type: 'page',
      actor: 'System',
      value: timeToLoad,
    },
  });

  logToTimberBI(
    biMethods.specialBiLog({
      specialEventType: 'WebViewerLoaded',
      userId: settingsManager.getConfigValue(configValues.userId) || 'NO_USER_ID_PARAM',
      companyId: settingsManager.getConfigValue(configValues.companyId) || 'NO_COMPANY_ID_PARAM',
      orderId: settingsManager.getConfigValue(configValues.orderId),
      rxId: settingsManager.getConfigValue(configValues.rxGuid),
      appName: 'web-3d-tool',
      appVersion,
      desktopSessionId: settingsManager.getConfigValue(configValues.SessionId) || null,
    })
  );
};

const pendoServiceHandler = ({ detail }) => {
  const { appSettings } = detail;
  if (appSettings) {
    const { getAllFlagsState } = appSettingsManager.getAppSettings();
    if (!getAllFlagsState) {
      return;
    }
    storeCommunicationService.unsubscribe(pendoServiceHandler);
    const isPendoAvailable = featureAvaliability.getIsPendoToggleAvailiable();
    const isPendoInitByDefaultForAllClients = featureAvaliability.getIsPendoInitByDefaultForAllClients();
    const { pendoFeatureFlagsPrefix, pendoApiKey } = appSettingsManager.getAppSettingsByValue(
      'environmentParametersSettings'
    );
    const pendoFeatureFlags = Object.keys(getAllFlagsState)
      .filter((key) => key.startsWith(pendoFeatureFlagsPrefix) && getAllFlagsState[key])
      .map((key) => key);
    const visitorId = settingsManager.getConfigValue(configValues.userId);
    const accountId = settingsManager.getConfigValue(configValues.companyId);

    const logInfoFromPendoService = (message) => {
      logger
        .info(message)
        .data({ module: 'pendo-service' })
        .end();
    };

    try {
      pendo(
        isPendoAvailable,
        isPendoInitByDefaultForAllClients,
        pendoFeatureFlags,
        pendoApiKey,
        visitorId,
        accountId,
        'web-3d-tool',
        logInfoFromPendoService
      );
    } catch (error) {
      logger
        .error('Pendo service error')
        .data({ module: 'pendo-service', error })
        .end();
    }
  }
};

const App = (props) => {
  const {
    isAOHS,
    pluginsZones,
    isThreejsObjectsReady,
    isPresetLoaded,
    appLoaded,
    presetLoaded,
    model: modelId,
    compareModel: compareModelId,
    metadata,
    compareMetadata,
    pluginsViews,
    stage,
    resetCameraRotationOnUpdate,
    modelIsLoading,
    isModelCompareActive,
    plugin360Parameters,
    isModelSynced,
    isModelCompareInDifferentMode,
    modelNiriData,
    compareModelNiriData,
  } = props;

  // states
  const [model, setModel] = useState(null);
  const [compareModel, setCompareModel] = useState(null);
  const [materialsObject, setMaterialsObject] = useState(null);
  const [compareMaterialsObject, setCompareMaterialsObject] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [isMountedCompare, setIsMountedCompare] = useState(false);
  const [isModelCompareUnloaded, setIsModelCompareUnloaded] = useState(false);
  const [isSplittedViewWithSidePluginActive, setIsSplittedViewWithSidePluginActive] = useState(false);

  // refs
  const frameDimentions = useRef({ width: 0, height: 0, enlargeWidth: 0, enlargeHeight: 0, drawerWidth: 0 });

  // memos
  const { currentExplorer, menuType, explorers } = plugin360Parameters || {};
  const getCorrectStageInCompareDifferentCaseTypes = useMemo(() => {
    if (!isModelCompareActive) return { correctStage: stage, compareRowIndex: 0 };

    const compareRowIndex = utils.getCompareRowIndex();
    const correctStage = compareRowIndex ? stage.map((pm) => pm.reverse()) : stage;

    return { correctStage, compareRowIndex };
  }, [isModelCompareActive, stage]);
  const isBothModelsHasNiriData = useMemo(() => !!(modelNiriData && compareModelNiriData), [
    compareModelNiriData,
    modelNiriData,
  ]);

  // params
  const dispatch = useDispatch();
  const onMount = () => setIsMounted(true);
  const onMountCompare = () => setIsMountedCompare(true);
  const isAohsInClearMode = AOHSService.isAOHSInClearMode();
  const isLoading = !(isMounted && isThreejsObjectsReady && isPresetLoaded) || modelIsLoading;
  const isModelCompareEnabled = isModelCompareActive && featureAvaliability.isSideBySideCompareEnabled();
  const isWasmEnabled = featureAvaliability.getIsWasmEnabled();
  const { correctStage, compareRowIndex } = getCorrectStageInCompareDifferentCaseTypes;
  const isCompareModelId = compareModelId && compareModelId.id;
  const imageFrameDimentions = frameDimentions.current;
  const isOnLanding = menuType === menuTypes360.CIRCULAR;

  // effects
  useEffect(() => {
    if (isMounted) {
      const timeToLoad = logger.timeEnd();
      logAboutAppLoaded(timeToLoad);
      const visibilityObject = cacheManager.get(cacheKeys.VISIBILITY_OBJECT);
      const model = cacheManager.get(cacheKeys.MODEL);
      eventBus.raiseEvent(globalEventsKeys.MODEL_LOADED, { model, visibilityObject });
    }
  }, [isMounted, modelId]);

  useEffect(() => {
    const compareModel = cacheManager.get(cacheKeys.COMPARE_MODEL);
    const visibilityObject = cacheManager.get(cacheKeys.COMPARE_VISIBILITY_OBJECT);

    if (isMountedCompare && compareModel && visibilityObject && !isModelCompareUnloaded) {
      eventBus.raiseEvent(globalEventsKeys.MODEL_LOADED, {
        model: compareModel,
        visibilityObject,
        isModelCompareLoaded: true,
      });
    }

    const compareModelUnloadEvent = eventBus.subscribeToEvent(globalEventsKeys.MODEL_UNLOADED, () => {
      setIsModelCompareUnloaded(true);
      compareModelUnloadEvent.unsubscribe();
    });

    if (isModelCompareUnloaded) {
      eventBus.raiseEvent(globalEventsKeys.AFTER_MODEL_UNLOAD_COMPLETTE);
      setIsModelCompareUnloaded(false);
    }
  }, [isMountedCompare, isCompareModelId, isModelCompareUnloaded]);

  useEffect(() => {
    const loadApp = async () => {
      const subscriptions = [];
      const { getIs360HubEnabled, isStandaloneMode } = utils;
      settingsManager.init();
      const isStandalone = isStandaloneMode();
      const is360 = getIs360HubEnabled();

      await appSettingsService.requestSingle({ selector: 'environmentParametersSettings' });
      if (isStandalone) {
        await appSettingsService.requestSingle({ selector: 'localhostMockSettings' });
      }

      appLoaded();

      if (is360) {
        subscriptions.push(
          eventBus.subscribeToEvent(globalEventsKeys.SPLITTED_VIEW_WITH_SIDE_PLUGIN_CHANGED, ({ isActive }) => {
            setIsSplittedViewWithSidePluginActive(isActive);
          }),

          eventBus.subscribeToEvent(globalEventsKeys.MODEL_UNLOADED, () => {
            setIsMountedCompare(false);
          }),

          eventBus.subscribeToEvent(globalEventsKeys.IMAGE_FRAME_DIMENTIONS_CHANGE, (dimentions) => {
            frameDimentions.current = { ...frameDimentions.current, ...dimentions };
          })
        );
      }

      logger.time('AT.APP_LOADED');
      window.addEventListener('resize', (e) => handleResizeWindow(e));
      storeCommunicationService.updateStore({ size: { width: window.innerWidth, height: window.innerHeight } });

      return () => {
        window.removeEventListener('resize', (e) => handleResizeWindow(e));
        subscriptions.forEach((subscription) => subscription && subscription.unsubscribe());
      };
    };

    loadApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const modelFromCache = cacheManager.get(cacheKeys.MODEL);
    const compareModelFromCache = cacheManager.get(cacheKeys.COMPARE_MODEL);
    setModel(modelFromCache);
    setCompareModel(compareModelFromCache);
    setMaterialsObject(cacheManager.get(cacheKeys.MATERIALS));
    setCompareMaterialsObject(cacheManager.get(cacheKeys.COMPARE_MATERIALS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId, compareModelId]);

  useEffect(() => {
    storeCommunicationService.initService(props, dispatch, updateReduxShellState);

    return () => {
      storeCommunicationService.clearStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (props.pluginsZones) {
      storeCommunicationService.updateStore(props);
    } else {
      const { pluginsZones, ...filteredProps } = props;
      storeCommunicationService.updateStore(filteredProps);
    }
  }, [props]);

  useEffect(() => {
    const loadWasmModule = async () => {
      await wasmService.init();
    };
    isWasmEnabled && loadWasmModule();
  }, [isWasmEnabled]);

  useEffect(() => {
    if (!isAohsInClearMode) {
      storeCommunicationService.subscribe(pendoServiceHandler);
      storeCommunicationService.updateStore({ size: { width: window.innerWidth, height: window.innerHeight } });
    }
  }, [isAohsInClearMode]);

  return (
    <div className={classNames(styles.app, currentExplorer ? styles.fadeInContainer : '')}>
      {model &&
        !modelIsLoading &&
        isPresetLoaded &&
        materialsObject &&
        renderStage({
          model,
          compareModel,
          metadata,
          compareMetadata,
          materialsObject,
          compareMaterialsObject,
          stage: correctStage,
          appProps: props,
          onMount,
          onMountCompare,
          resetCameraRotationOnUpdate,
          menuType360: menuType,
          isOnLanding,
          imageFrameDimentions,
          isModelCompareEnabled,
          isModelCompareActive,
          isModelSynced,
          isSplittedViewWithSidePluginActive,
          isModelCompareInDifferentMode,
          explorers,
          compareRowIndex,
          isAOHS,
          isBothModelsHasNiriData,
        })}

      {explorers && !isAOHS && (
        <ConditionNavigation explorers={explorers} activeExplorer={currentExplorer}></ConditionNavigation>
      )}
      {pluginsZones && <AppPreset zones={pluginsZones} presetLoaded={presetLoaded} />}
      {pluginsViews && <PluginsViews pluginsViews={pluginsViews} />}
      {isLoading && (
        <div className={styles.progress}>
          <LoadingProgress width={80} height={80} />
        </div>
      )}
      <div className={styles.appVersion} id="appVersion">
        [ {appVersion} ]
      </div>
    </div>
  );
};

const mapStateToProps = ({ renderer, shell, plugins }) => {
  const {
    model,
    compareModel,
    metadata,
    compareMetadata,
    stage,
    isThreejsObjectsReady,
    resetCameraRotationOnUpdate,
    modelIsLoading,
    isModelCompareActive = false,
    isModelSynced = false,
    isModelCompareInDifferentMode = false,
    modelNiriData,
    compareModelNiriData,
  } = renderer;
  const { pluginsZones, pluginsViews, isPresetLoaded, isAOHS, context } = shell;
  const plugin360Parameters = plugins[TOOGLE_MENU360.id].pluginParameters;

  return {
    model,
    compareModel,
    pluginsZones,
    metadata,
    compareMetadata,
    pluginsViews,
    stage,
    isThreejsObjectsReady,
    isPresetLoaded,
    resetCameraRotationOnUpdate,
    modelIsLoading,
    isModelCompareActive,
    plugin360Parameters,
    isModelSynced,
    isModelCompareInDifferentMode,
    isAOHS,
    modelNiriData,
    compareModelNiriData,
    context,
    plugins,
  };
};

export default connect(
  mapStateToProps,
  {
    appLoaded,
    presetLoaded,
    imperativeThreeObjectsReady,
    cameraStoppedMoving,
    twoFingersDoubleTap,
  }
)(App);
